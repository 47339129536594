import React, { Fragment, useEffect, useState, useRef } from 'react';
import './topics.scss';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
// import PlayListPlayer from './PlayListPlayer';
import TopicDetails from '../../../utils/topicDetails';
import {
    getSubjectChapters, getChapterTopics, resetTopicLoading,
    getTopicResources, getResource, resetIndex, getPrevIndex, getQuizdata, getQuestion, reTake, selectAnswer, quizSubmitted,
    viewAnalytic, likeResource, savedNote, getNote, backToTopic
} from '../../../Redux/ActionCreators/myLearningAction';
import { openShare } from '../../../Redux/ActionCreators/shareAction';
import { hideSideBar } from '../../../Redux/ActionCreators/manageContentAction';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { TopicLoader } from '../../../Loader/loader';
import EllipsisText from 'react-ellipsis-text';
import ReactPlayer from 'react-player';
import PdfReader from '../../../utils/pdfReader';
import Modal from 'react-bootstrap/Modal';
import { Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SharePopup from '../../Share-popup/sharePopup';
import { secondsToTime } from '../../../utils/miscelleneous';

var captured_time = 0, start_time = 0, interval_id, resourceTime, win;
var timer, timer1
var visible



export default function Topics() {
    const [topic, setTopic] = useState('');
    const [topicId, setTopicId] = useState('');
    const [seconds, setSeconds] = useState(0);
    const [time, setTime] = useState(null);
    const [resourceId, setResourceId] = useState('');
    const [resourceType, setResourceType] = useState('');
    const [title, setTitle] = useState('');
    const [chapter, setChapter] = useState('');
    const [chapterId, setChapterId] = useState('');
    const [rCount, setRCount] = useState('');
    const [id, setId] = useState('');
    const [articleView, setArticleView] = useState(false);
    const [count, setCount] = useState(0);
    const [quiz, setQuiz] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const [target, setTarget] = useState(null);
    const [noteValue, setNoteValue] = useState('');
    const [isEdit, setIsEdit] = useState(true);
    const [isEditNo, setIsEditNo] = useState(false);
    const [charsLeft, setCharLeft] = useState(250);
    const [saveError, setSaveError] = useState('');
    const [isBuffering, setIsBuffering] = useState(false);
    const [isEnd, setIsEnd] = useState(false);
    const [isPause, setIsPause] = useState(false);
    const maxChar = 250;
    // const [individualResource, setActiveFeedData] = useState(null)
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isShow, subjectChapters, chapterTopics, topicLoading, resources, resourceIndex, topics, isLoadingResource, individualResource, note,
        qIndex, questions, question, score,
    } = useSelector(state => state.myLearning);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(resetIndex());

        const firstRender = async () => {
            await (dispatch(hideSideBar()))
            const id = location.state.id;
            setChapterId(location.state.id);
            await dispatch(resetTopicLoading());
            await dispatch(getSubjectChapters(location.state.subjectId))
            const res = await dispatch(getChapterTopics(id, location.state.subjectId));
            setChapter(location.state.chapter);
            await dispatch(getTopicResources(id));
            await setTopic(res.payload[0]?.name);
            const total = location.state.detail?.articles + location.state.detail?.videos + location.state.detail?.documents + location.state.detail?.quizzes
            setRCount(total);
        }
        firstRender();

    }, [])

    const selectChapter = async (detail) => {
        const total = detail?.articles + detail?.videos + detail?.documents + detail?.quizzes
        setRCount(total);
        if (detail?.name === chapter) {
            setChapter('');
        } else {
            setChapter(detail?.name);
            setChapterId(detail?._id);
            await dispatch(resetTopicLoading());
            const res = await dispatch(getChapterTopics(detail?._id, location.state.subjectId));
            if (res?.type === 'GET_CHAPTER_TOPICS') {
                await dispatch(getTopicResources(detail._id));
                await setTopic(res?.payload[0]?.name);

            }
        }
    }


    const selectSubtopic = async (val, id) => {
        if (val === topic) {
            setTopic('');
            // setTopicId('')
        } else {
            setTopic(val);
            setTopicId(id);
        }
    }



    const backtoBubble = () => {
        navigate(-1);
    }



    const showfeeds = async (el) => {
        await setResourceId(el?._id);
        await setResourceType(el?.type);
        await setTopic(el?.topic_name);
        await setTopicId(el.topic_id);
        await stopTimer();
        await setIsEnd(false);
        await dispatch(resetIndex());
        const res = await dispatch(getResource(el));
        // await onPlayChange();
        if (res?.type === 'GET_INDIVIDUAL_RESOURCE') {
            individualResource?.type !== 'Quiz' && await dispatch(getNote(el._id));
            await startTimer();
            await setIsEditNo(true);
            await setIsEdit(false);

            if (el.type === 'Article' || el.type === 'Document') {
                // await onPlayChange(el);
                resourceTime = (individualResource?.duration && individualResource?.duration !== null) ? individualResource.duration : 120000;
            }
            else if (el.type === 'Quiz') {
                resourceTime = 0
            }
        }
    }

    const showfeeds1 = async (el) => {
        setIsEnd(false);

        var local_data = localStorage.getItem('time')

        // ==== CALLING ANALYTICS API FOR PREVIOUS DATA ===
        if (local_data) {
            clearInterval(interval_id);
            clearInterval(id);
            local_data = JSON.parse(local_data);
            let article_time = individualResource?.type === "Article" ? localStorage.getItem("aTime") !== null ? JSON.parse(localStorage.getItem("aTime")) : 0 : 0;
            if (local_data.resource_id) {
                if ((local_data.time_spent + article_time) / 1000 > Math.round(resourceTime * 0.40)) {
                    local_data.time_spent = Math.round((local_data.time_spent + article_time))
                    local_data.viewed = resourceTime === null ? false : true
                    local_data.topic_id = topicId;
                    local_data.resource_id = resourceId;
                    local_data.type = individualResource?.type || resourceType;
                    local_data.chapter_id = chapterId;
                    local_data.subject_id = location.state.subjectId;
                    const update = local_data
                    await dispatch(viewAnalytic(update));
                }
                else {
                    local_data.viewed = false;
                    local_data.resource_id = resourceId;
                    local_data.type = individualResource?.type || resourceType;
                    local_data.time_spent = Math.round((local_data.time_spent + article_time))
                    local_data.topic_id = topicId;
                    local_data.chapter_id = chapterId;
                    local_data.subject_id = location.state.subjectId;
                    const update = local_data
                    await dispatch(viewAnalytic(update));
                }
            }
        }


        await setResourceId(el?._id);
        await setResourceType(el?.type);
        await setTopic(el?.topic_name);
        await setTopicId(el.topic_id);
        await stopTimer();
        await dispatch(resetIndex());
        const res = await dispatch(getResource(el));
        // await onPlayChange();
        if (res?.type === 'GET_INDIVIDUAL_RESOURCE') {
            individualResource?.type !== 'Quiz' && await dispatch(getNote(el._id));
            await startTimer();
            await setIsEditNo(true);
            await setIsEdit(false);

            if (el.type === 'Article' || el.type === 'Document') {
                // await onPlayChange(el);
                resourceTime = (individualResource?.duration && individualResource?.duration !== null) ? individualResource.duration : 120000;
            }
            else if (el.type === 'Quiz') {
                resourceTime = 0
            }
        }
    }

    const showQuiz = async (el) => {
        await setResourceId(el?._id);
        await setResourceType(el?.type);
        await setTopic(el?.topic_name);
        await setTopicId(el.topic_id);
        await dispatch(resetIndex());
        await dispatch(getQuizdata(el));
        await setQuizCompleted(false);
        // await setQuiz(false);
        if (el.type === 'Quiz') {
            clearInterval(timer);
            setSeconds(0);
            setTime(null);
            await startTimer();
            resourceTime = 0
        }
    }


    const showArticles = async (el) => {
        window.scrollTo(0, 0);
        setResourceId(el?._id);
        setResourceType(el?.Type);
        setTopic(el.topic_name)
        setTopicId(el.topic_id);
        await dispatch(getResource(el));
        if (el?.type !== "Video") {
            await startTimer();
            if (el.type === "Article") {
                // onPlayChange();
                resourceTime = individualResource?.duration && individualResource?.duration !== null ? individualResource.duration : 120000;
                goToArticles(individualResource?.url, true);
            }
        }
    }

    const goToArticles = async (url, close) => {

        if (close) {
            setArticleView(true);
            window.innerWidth > 767 && $(".layout").show();
            // await onPlayChange();
            var start_time = new Date();
            win = window.open(url, 'name', 'location=1,status=1,scrollbars=0,width=800,height=800,menubar=no,status=no,toolbar=no');
            win.moveTo(400, 0);
            if (win) win.focus();
            window.innerWidth > 767 ? await pauseTimer() : await startTimer();
            timer1 = setInterval(() => {
                if (win.closed) {
                    clearInterval(timer1);
                    setArticleView(false)
                    var end_time = new Date();

                    var elapsed_ms = end_time - start_time;
                    var seconds = Math.round(elapsed_ms / 1000);
                    var minutes = Math.round(seconds / 60);
                    // var hours = Math.round(minutes / 60);

                    var sec = seconds;
                    localStorage.setItem('aTime', sec * 1000)
                    $(".layout").hide();
                    window.innerWidth > 767 && startTimer();
                }
            }, 1000);
        } else {
            win && win.close();
            clearInterval(timer1);
            setArticleView(false);
            $(".layout").hide();
            await startTimer();
        }

    }


    const prevIndex = async (items) => {
        setIsEnd(false);
        await dispatch(resetIndex());
        clearInterval(timer1);
        var local_data = localStorage.getItem('time');
        // ==== CALLING ANALYTICS API FOR PREVIOUS DATA ===
        if (local_data) {
            clearInterval(interval_id);
            clearInterval(id);
            local_data = JSON.parse(local_data);
            let article_time = individualResource?.type === "Article" ? localStorage.getItem("aTime") !== null ? JSON.parse(localStorage.getItem("aTime")) : 0 : 0;
            if (local_data.resource_id) {
                if ((local_data.time_spent + article_time) / 1000 > Math.round(resourceTime * 0.40)) {
                    local_data.time_spent = Math.round((seconds * 1000) + article_time)
                    local_data.viewed = resourceTime === null ? false : true
                    local_data.topic_id = topicId;
                    local_data.resource_id = resourceId;
                    local_data.type = individualResource?.type ? individualResource?.type : resourceType;
                    local_data.chapter_id = chapterId;
                    local_data.subject_id = location.state.subjectId;
                    const update = local_data
                    individualResource?.type !== 'Quiz' && await dispatch(viewAnalytic(update));
                }
                else {
                    local_data.viewed = false
                    local_data.resource_id = resourceId;
                    local_data.type = individualResource?.type ? individualResource?.type : resourceType;
                    local_data.time_spent = Math.round(local_data.time_spent + article_time)
                    local_data.topic_id = topicId;
                    local_data.chapter_id = chapterId;
                    local_data.subject_id = location.state.subjectId
                    const update = local_data;
                    individualResource?.type !== 'Quiz' && await dispatch(viewAnalytic(update));
                }
            } else {
                local_data.viewed = false
                local_data.resource_id = resourceId;
                local_data.type = individualResource?.type ? individualResource?.type : resourceType;
                local_data.time_spent = Math.round((seconds * 1000) + article_time)
                local_data.topic_id = topicId;
                local_data.chapter_id = chapterId;
                local_data.subject_id = location.state.subjectId;
                const update = local_data;
                individualResource?.type !== 'Quiz' && await dispatch(viewAnalytic(update));
                // await onPause(true);
            }
        }

        await setResourceId(items._id);
        await setResourceType(items?.type);
        await setTitle(items.topic_name);
        await setTopicId(items.topic_id);
        setIsEdit(false);
        setIsEditNo(true);
        localStorage.setItem('aTime', 0)
        await stopTimer();

        items.type === 'Quiz' ? await dispatch(getQuizdata(items)) : await dispatch(getResource(items));
        items.type !== 'Quiz' && dispatch(getNote(items._id));
        await startTimer();
        if (items?.type === 'Article' || items.type === 'Document') {
            // await onPause(true);
            resourceTime = (individualResource.duration && individualResource.duration !== null) ? individualResource?.duration : 120000;
            // await onPlayChange();
        }
        if (items.type !== 'Quiz' && items.type !== 'Video') {
            clearInterval(timer);
            setSeconds(0);
            setTime(null);
            await startTimer();
        }
    }

    const onPause = async (isTrue) => {
        await pauseTimer();
        let local_data = localStorage.getItem('time'),
            timestamp = new Date();
        if (individualResource?.type === 'Video') {
            let startTime = individualResource?.time !== null && individualResource?.time?.split('-')[0].split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
            let endTime = individualResource?.time !== null && individualResource?.time?.split('-')[1].split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0)
            resourceTime = resourceTime === null ? 120 : ((endTime - startTime) === 0 ? 120 : (endTime - startTime))
        }
        let article_time = individualResource?.type === "Article" ? localStorage.getItem("aTime") !== null ? JSON.parse(localStorage.getItem("aTime")) : 0 : 0;
        if (local_data) {
            local_data = JSON.parse(local_data);
            if (!isTrue) {
                if ((local_data.time_spent + article_time) / 1000 > Math.round(resourceTime * 0.40)) {
                    local_data.time_spent = Math.round((local_data.time_spent + article_time))
                    local_data.topic_id = topicId;
                    local_data.type = individualResource?.type;
                    local_data.resource_id = individualResource?._id;
                    local_data.viewed = resourceTime === null ? false : true
                    local_data.chapter_id = chapterId;
                    local_data.subject_id = location.state.subjectId;
                    const update = local_data
                    individualResource?.type !== 'Quiz' && await dispatch(viewAnalytic(update));

                }
                else {
                    local_data.time_spent = Math.round((local_data.time_spent + article_time));
                    local_data.topic_id = topicId;
                    local_data.type = individualResource?.type;
                    local_data.resource_id = individualResource?._id;
                    local_data.viewed = false;
                    local_data.chapter_id = chapterId;
                    local_data.subject_id = location.state.subjectId;
                    const update = local_data
                    individualResource?.type !== 'Quiz' && await dispatch(viewAnalytic(update));
                }
            }
            if (local_data.resource_id === individualResource?._id) {
                captured_time = local_data.time_spent + article_time;
                timestamp = local_data.timestamp;
            }
            else {
                captured_time = 0;
            }
        }

        clearInterval(id);
        var end_time = new Date().getTime();
        const obj = {
            "resource_id": individualResource?._id,
            "type": individualResource?.type,
            "topic_id": topicId,
            "time_spent": seconds * 1000,
            chapter_id: chapterId,
            subject_id: location.state.subjectId,
            browser: localStorage.getItem('browser'),
            device: localStorage.getItem('device'),
            timestamp: timestamp,
        }

        localStorage.setItem('time', JSON.stringify(obj));

    }

    const onPlayChange = (el) => {
        if (individualResource?.type !== 'Video' && !timer && isShow) {
            startTimer();
        }
        setIsPause(false);
        // var timestamp = new Date();
        start_time = new Date().getTime();
        if (individualResource?.type === 'Video') {
            let startTime = individualResource?.time !== null && individualResource?.time?.split('-')[0]?.split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
            let endTime = individualResource?.time !== null && individualResource?.time?.split('-')[1]?.split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0)
            resourceTime = resourceTime === null ? 120 : ((endTime - startTime) === 0 ? 120 : (endTime - startTime));
            // onPause(true);
        }
        // let article_time = individualResource?.type === "Article" ? localStorage.getItem("aTime") !== null ? JSON.parse(localStorage.getItem("aTime")) : 0 : 0;
        // interval_id = setInterval(() => {
        //     let local_data = localStorage.getItem('time');

        //     if (local_data) {
        //         local_data = JSON.parse(local_data);

        //         if (local_data.resource_id === individualResource?._id) {
        //             captured_time = local_data.time_spent;
        //             timestamp = local_data.timestamp;
        //         }
        //         else {
        //             localStorage.removeItem('time');
        //             captured_time = 0;
        //         }
        //     }

        //     var current_time = new Date().getTime();
        //     const obj = {
        //         browser: localStorage.getItem('browser'),
        //         device: localStorage.getItem('device'),
        //         timestamp: timestamp,
        //         "resource_id": el ? el._id : individualResource?._id,
        //         "type": el ? el?.type : individualResource?.type,
        //         "topic_id": topicId,
        //         "time_spent": seconds * 1000
        //     }
        //     start_time = new Date().getTime();
        //     localStorage.setItem('time', JSON.stringify(obj))
        // }, 1000);
        // setId(interval_id);
    }

    const back = async () => {
        isShow && await onPause(false);
        isShow ? dispatch(backToTopic()) : navigate(-1)
    }


    const onLikeClick = async (el) => {
        let obj = {
            "resource_id": el._id,
            "type": el.type,
            "like": !el.like
        }
        await dispatch(likeResource(obj));
    }

    useEffect(() => {
        // setResourceId(individualResource?._id);
        // setResourceType(individualResource?.type);
        setNoteValue(note !== null ? note : '');
        setCharLeft(note !== null ? 250 - note.length : 250)
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };

    }, [note]);

    const changeNotes = (val) => {
        const charCount = val.length;
        const charLength = maxChar - charCount;
        setNoteValue(val);
        setCharLeft(charLength);
    }


    const saveNote = async (id, type, note) => {
        if (note === '') {
            setSaveError('Please enter notes');
        }
        else {
            setSaveError('');
            const save = {
                resource_id: id,
                type: type,
                subject_id: location.state.subjectId,
                chapter: location.state.chapter,
                chapter_id: chapterId,
                text: note
            }
            await dispatch(savedNote(save));
            setIsEdit(false);
            setIsEditNo(true);
        }
    }

    const editNote = () => {
        $("#note").prop("disabled", false);
        setIsEdit(true);
        setIsEditNo(true);
    }

    const cancel = () => {
        setIsEdit(false);
        setIsEditNo(true);
    }

    const timerRef = useRef(null);

    const startTimer = () => {
        if (timerRef.current) return;
        timerRef.current = setInterval(() => {
            setSeconds((prevSeconds) => {
                let timestamp = new Date();
                const updatedSeconds = prevSeconds + 1;
                const obj = {
                    browser: localStorage.getItem('browser'),
                    device: localStorage.getItem('device'),
                    timestamp: timestamp,
                    "resource_id": individualResource?._id,
                    "type": individualResource?.type,
                    chapter_id: chapterId,
                    subject_id: location.state.subjectId,
                    "topic_id": topicId,
                    "time_spent": updatedSeconds * 1000
                }
                localStorage.setItem('time', JSON.stringify(obj));
                setTime(secondToTime(updatedSeconds));
                return updatedSeconds;
            });
        }, 1000);
    };


    const secondToTime = (secs) => {
        let hours = Math.floor(secs / 3600);
        let minutes = Math.floor((secs % 3600) / 60);
        let seconds = secs % 60;

        return {
            h: hours,
            m: (minutes < 10 ? "0" : "") + minutes,
            s: (seconds < 10 ? "0" : "") + seconds,
        };
    };

    const handleVisibilityChange = () => {
        if (document.hidden) {
            individualResource?.type !== 'Article' && pauseTimer(); // pause timer when tab is inactive
            (individualResource?.type === 'Article' && window.innerWidth < 767) && startTimer();
        } else if (!isBuffering && !isPause) {
            startTimer(); // Start timer when tab is active and not buffering
        }
    };

    useEffect(() => {
        // Start the timer initially
        startTimer();

        // Attach visibility change event listener
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Cleanup on unmount
        return () => {
            pauseTimer();
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    const pauseTimer = () => {
        setIsPause(true);
        clearInterval(timerRef.current);
        timerRef.current = null;
    };

    const stopTimer = () => {
        pauseTimer();
        setSeconds(0);
        setTime({ h: 0, m: "00", s: "00" });
        // startTimer();
    };

    useEffect(() => {
        return () => {
            clearInterval(timerRef.current); // Cleanup on unmount
        };
    }, []);

    const handleProgress = ({ playedSeconds, loadedSeconds }) => {
        if (loadedSeconds - playedSeconds < 2) {
            // If loaded buffer is less than 2 seconds ahead of the current playhead
            handleBuffer();
        } else {
            handleBufferEnd();
        }
    };

    const handleBuffer = () => {
        setIsBuffering(true);
        pauseTimer(); // Pause timer during buffering
    };

    const handleBufferEnd = () => {
        setIsBuffering(false);
        if (!isPause) {
            !isEnd && startTimer();
        }
    };

    const onEnded = async (e) => {
        setIsEnd(true);
        await pauseTimer();
        let local_data = JSON.parse(localStorage.getItem('time'));
        local_data.resource_id = resourceId;
        local_data.topic_id = topicId;
        local_data.type = resourceType;
        local_data.viewed = true;
        local_data.time_spent = seconds * 1000
        local_data.chapter_id = chapterId;
        local_data.subject_id = location.state.subjectId;
        localStorage.setItem('time', JSON.stringify(local_data))
        clearInterval(id);
        // await dispatch(viewAnalytic(local_data));
    }

    const goToQuestion = async (ques, qIndex) => {
        window.scrollTo(0, 0);
        setCount((qIndex === -1 ? 0 : qIndex));
        setQuiz(true);
        setQuizCompleted(false);
        if (qIndex === -1) {
            // await dispatch(reTake(individualResource._id))
            await dispatch(getQuestion(ques));
        }
        else {
            dispatch(getQuestion(ques));
        }
    }

    const quizsubmit = async () => {
        let obj = {
            test_id: resourceId,
            type: 'quiz',
            data: questions.filter(el => el.answer !== null)?.map(el => [{ q_id: el._id, a_id: el.answer }][0])
        }
        await dispatch(quizSubmitted(obj));
        await setQuizCompleted(true);
        await setQuiz(false);
        let local_data = JSON.parse(localStorage.getItem('time'));
        local_data.resource_id = resourceId;
        local_data.topic_id = topicId;
        local_data.type = 'Quiz';
        local_data.viewed = true;
        local_data.time_spent = 0;
        local_data.chapter_id = chapterId;
        local_data.subject_id = location.state.subjectId;
        await dispatch(viewAnalytic(local_data));
    }


    if (individualResource?.type === 'Video') {
        var start = individualResource?.time !== null && individualResource?.time?.split('-')[0].split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        var end = individualResource?.time !== null && individualResource?.time?.split('-')[1].split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        var time1 = secondsToTime(end - start);
    }
    const currentIndex = resourceIndex !== undefined ? resourceIndex?.findIndex(el => el._id === individualResource?._id) : 0
    // eslint-disable-next-line no-unused-vars
    const above = resourceIndex !== undefined && resourceIndex[currentIndex + 1]
    const below = resourceIndex !== undefined && resourceIndex[currentIndex - 1]

    return (
        <div className='chapter-topic-details'>

            {window.innerWidth > 767 ?
                <div className="bc-nav ml-2 mt-2" >
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {/* <li className="breadcrumb-item"><img src='/images/home.svg' alt='' /></li> */}
                            {window.location.pathname === '/my-learning/topics' ?
                                <li className="breadcrumb-item cursor-pointer" onClick={() => {
                                    navigate('/my-learning')
                                    isShow && onPause(false)
                                    clearInterval(interval_id)
                                    isShow && stopTimer()
                                }} >{t('My Learning')}</li> :
                                <li className="breadcrumb-item cursor-pointer" onClick={() => {
                                    navigate('/my-subjects')
                                    isShow && onPause(false)
                                    clearInterval(interval_id)
                                    isShow && stopTimer()
                                }} >{t('My Subjects')}</li>
                            }
                            <li className="breadcrumb-item cursor-pointer" onClick={() => {
                                navigate(-1)
                                // isShow && onPause(false)
                                clearInterval(interval_id)
                                isShow && stopTimer()
                            }}>{location.state?.subject}</li>
                            <li className="breadcrumb-item  b-active">{chapter}</li>
                        </ol>
                    </nav>
                </div> :
                null
            }
            {(window.innerWidth > 767) ?
                <div className='topic-header'>
                    {(window.innerWidth > 767 && isShow) && <img className='cursor-pointer' src='/images/back-white.svg' alt='' onClick={() => back()} />} {location.state.subject}
                </div> :
                ((window.innerWidth < 767)) ?
                    <img src='/images/back.svg' className='cursor-pointer' alt='' onClick={() => back()} />
                    :
                    null
            }


            {
                !isShow ?
                    <TopicDetails
                        t={t}
                        location={location}
                        subjectChapters={subjectChapters}
                        chapterTopics={chapterTopics}
                        selectChapter={selectChapter}
                        chapter={chapter}
                        selectSubtopic={selectSubtopic}
                        topic={topic}
                        topicLoading={topicLoading}
                        rCount={rCount}
                        topics={topics}
                        resources={resources}
                        backtoBubble={backtoBubble}
                        title={location?.state?.title}
                        showfeeds={showfeeds}
                        showQuiz={showQuiz}
                        resourceId={resourceId}
                    />
                    :
                    <div className={window.innerWidth > 767 ? 'd-flex w-100 mt-2' : 'd-flex flex-wrap w-100 mt-2'}>
                        <div className='chapter-topics'>
                            <div className="acc-overflow">
                                <div id="accordian" >
                                    <Fragment>

                                        {subjectChapters !== undefined &&
                                            subjectChapters.filter(el => el.subtopics?.length)?.map((el, i) => {

                                                return (
                                                    <div key={el._id} className="card p-0" style={{ background: chapter === el.name ? '#413C69' : '#fff' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            selectChapter(el);
                                                        }}
                                                    >
                                                        <div style={{ marginRight: 0, }} className="d-flex justify-content-between align-items-center cursor-pointer">
                                                            <div className="col-md-12 col-12 pr-0 recent-title" >
                                                                <div className="d-flex justify-content-between align-items-center cursor-pointer">
                                                                    <h1 style={{ fontSize: 14, color: chapter === el.name ? '#fff' : '' }}>
                                                                        {el.name !== undefined && (
                                                                            <EllipsisText text={el.name} length={"80"} />
                                                                        )}
                                                                    </h1>
                                                                    <div className="d-flex float-right" style={{ marginRight: -10, }}>
                                                                        <div className="d-flex align-items-center" style={{ width: 30, }}>
                                                                            <img src={chapter === el.name ? "/images/list-color-white.svg" : "/images/list-color.svg"} alt="" width={16} />
                                                                            &nbsp;
                                                                            <h5 style={{ color: chapter === el.name ? '#fff' : '' }} >
                                                                                {
                                                                                    el.articles + el.videos + el.documents + el.quizzes
                                                                                }
                                                                            </h5>
                                                                        </div>{" "}
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        <div className="d-flex align-items-center" style={{ width: 52, }}>
                                                                            <img src={chapter === el.name ? "/images/pie-icon-white.svg" : "/images/pie-icon.svg"} alt="" width={16} />
                                                                            &nbsp;
                                                                            <h5 style={{ color: chapter === el.name ? '#fff' : '' }}    >
                                                                                {el.analytic > 95 ? 100 : (el.analytic || 0)}%
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id={el._id} className={chapter === el.name ? 'card-body p-0 collapse show' : 'card-body p-0 collapse'} data-parent="#accordian"  >
                                                            {
                                                                el.subtopics !== undefined &&
                                                                el.subtopics.map((item, index) => {
                                                                    return (
                                                                        <div key={item?._id} className="card mb-0 p-0" style={{ backgroundColor: topic === item[0]?.topic_name ? '#EEEDFF' : "#fff" }} >
                                                                            <div className={topic === item[0]?.topic_name ? "card-header" : "card-header collapsed"} data-toggle="collapse" aria-expanded="true" href={`#${index}`} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                selectSubtopic(item[0]?.topic_name, item[0]?.topic_id)
                                                                            }} >
                                                                                <div className="card-title" style={{ color: topic === item[0]?.topic_name ? '#413C69' : '#000000' }} >
                                                                                    <p style={{ marginBottom: 3, marginLeft: 10 }}> {item[0]?.topic_name} </p>
                                                                                </div>
                                                                            </div>
                                                                            <div id={index} className={topic === item[0]?.topic_name ? 'card-body collapse show p-0' : 'card-body collapse p-0'} data-parent="#accordion"  >
                                                                                <div >
                                                                                    {(
                                                                                        <div id='resource-list' className="resource" style={{ background: '#fff' }}>
                                                                                            {
                                                                                                item.map((items, index) => {
                                                                                                    return (
                                                                                                        <div key={items._id} className="col-md-12 col-12 pr-0 recent-title" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            items?.type === 'Quiz' ? showQuiz(items) : showfeeds1(items);
                                                                                                            // dispatch(getTopicResources(el._id));
                                                                                                        }}>

                                                                                                            <h1 className='d-flex align-items-center' style={{ lineHeight: 1.5, fontSize: 14, marginLeft: 10, color: items._id === resourceId ? '#413c69' : '#272727', fontWeight: items._id === resourceId ? '700' : '500' }}>
                                                                                                                {
                                                                                                                    items.type === 'Video' ?
                                                                                                                        <img src='/images/video-box-icon.svg' alt='' width={20} /> :
                                                                                                                        items.type === 'Document' ?
                                                                                                                            <img src='/images/document-icon.svg' alt='' width={20} /> :
                                                                                                                            items.type === 'Article' ?
                                                                                                                                <img src='/images/article-box-icon.svg' alt='' width={20} /> :
                                                                                                                                items.type === 'Quiz' ?
                                                                                                                                    <img src='/images/quiz.svg' alt='' width={20} /> :
                                                                                                                                    null
                                                                                                                }&nbsp;&nbsp;
                                                                                                                {items.title !== undefined && (
                                                                                                                    <EllipsisText text={items.title} length={"80"} />
                                                                                                                )}
                                                                                                            </h1>
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            any                                                </div>
                                                    </div>
                                                );
                                            })}
                                    </Fragment>
                                </div>
                            </div>
                        </div>
                        <div className="chapter-content">
                            <div id="accordion" className="accordion">
                                {
                                    topicLoading ?
                                        <TopicLoader />
                                        : isLoadingResource ?
                                            'Loading...' :
                                            <div className='topic-content'>
                                                <div className='resource-header'>
                                                    <div className='d-flex align-items-center' style={{ width: window.innerWidth > 767 ? '70%' : '100%' }}>
                                                        {
                                                            individualResource.type === 'Video' ?
                                                                <img src='/images/video-box-icon.svg' alt='' width={20} /> :
                                                                individualResource.type === 'Article' ?
                                                                    <img src='/images/article-box-icon.svg' alt='' width={20} /> :
                                                                    individualResource.type === 'Quiz' ?
                                                                        <img src='/images/quiz.svg' alt='' width={20} /> :
                                                                        null
                                                        }&nbsp;&nbsp;
                                                        <h2>{individualResource?.type}:{individualResource?.title} </h2><br />
                                                    </div>

                                                    {quiz ? null :
                                                        <div className='prev-next'>
                                                            <h6
                                                                style={{ visibility: currentIndex === 0 ? 'hidden' : 'visible' }}
                                                                onClick={() => prevIndex(below)}
                                                            >
                                                                <img src='/images/chevron-right.svg' alt='' style={{ transform: 'rotate(180deg)' }} />&nbsp;&nbsp;
                                                                {t('Previous')}
                                                            </h6>
                                                            <h6
                                                                style={{ visibility: currentIndex === (resourceIndex?.length - 1) ? 'hidden' : 'visible' }}
                                                                onClick={() => prevIndex(above)}
                                                            > {t('Next')}&nbsp;&nbsp;
                                                                <img src='/images/chevron-right.svg' alt='' />
                                                            </h6>
                                                        </div>
                                                    }

                                                </div>
                                                <div className='d-flex align-items-center mt-1'>
                                                    {individualResource?.type === 'Article' ? <p className='d-flex align-items-center'><img src='/images/duration.png' alt='' width={20} height={20} /> &nbsp;&nbsp;{'02:00'}</p> :
                                                        (time1 &&
                                                            <p className='d-flex align-items-center'><img src='/images/duration.png' alt='' width={20} height={20} /> &nbsp;&nbsp;{time1}</p>)
                                                    }
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                        isShow &&
                                                        <p className='d-flex align-items-center' style={{ visibility: 'hidden' }}>
                                                            <img src='/images/stopwatch.png' alt='' width={20} height={20} />&nbsp; {time?.h}:{time?.m}:{time?.s}
                                                        </p>}
                                                </div>
                                                <div className='resource-description'>
                                                    {
                                                        isLoadingResource ?
                                                            'loading...' :
                                                            (individualResource?.type === 'Quiz' ?
                                                                <Fragment>
                                                                    <div className='quiz-holder text-center'>
                                                                        {
                                                                            quiz ?
                                                                                <div className='col-md-6 offset-md-3 ' style={{ marginTop: window.innerWidth > 767 ? 0 : 75 }}>
                                                                                    <p className='question-header'>{t('Question')} {count + 1}/{questions?.length}</p>
                                                                                    <small className='ques-title' dangerouslySetInnerHTML={{ __html: question?.description }} />
                                                                                    <ul className='options'>
                                                                                        {
                                                                                            question?.options !== undefined && question?.options.map((ans, index) => {
                                                                                                return (
                                                                                                    <li className='row flex-nowrap position-relative' key={ans?._id} style={{ pointerEvents: question.answer !== null ? 'none' : 'auto', width: window.innerWidth > 767 ? '100%' : '100%' }} >
                                                                                                        <div className='col-md-1 col-1'>
                                                                                                            {question?.correct_id === ans._id && question?.correct ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : question?.correct === false && question?.answer === ans._id ? <img src='/images/incorrect.svg' style={{ verticalAlign: 'middle' }} alt='' /> : question?.correct_id === ans._id ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : null}
                                                                                                        </div>
                                                                                                        <div className={question?.correct_id === ans._id && question?.correct ? 'correct option col-md-7 col-8' : question?.correct === false && question?.answer === ans._id ? 'incorrect option col-md-7 col-8' : question?.correct_id === ans._id ? 'correct option col-md-7 col-8' : 'option1 col-md-7 col-8'} onClick={(e) => {
                                                                                                            dispatch(selectAnswer(question?._id, ans._id))
                                                                                                        }} dangerouslySetInnerHTML={{ __html: ans.title }}
                                                                                                        />


                                                                                                        <div className='col-md-2 col-2' >
                                                                                                            {question?.explanation !== null ?
                                                                                                                (window.innerWidth > 767 && question?.correct_id === ans._id) ?
                                                                                                                    showExplanation ?
                                                                                                                        <div className='explanation-popup' >
                                                                                                                            <h1>{t('Explanation')}</h1>
                                                                                                                            <div className='close-explaination' onClick={() => setShowExplanation(false)}><img src='/images/close1.svg' alt='' width='10px' /></div>
                                                                                                                            <div style={{ textAlign: 'left', marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: question.explanation }}></div>
                                                                                                                        </div> :
                                                                                                                        <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => {
                                                                                                                            setTarget(e.target);
                                                                                                                            setShowExplanation(true);
                                                                                                                        }} />
                                                                                                                    : question.correct_id === ans._id && <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => setShowExplanation(true)} />
                                                                                                                : null
                                                                                                            }
                                                                                                            {
                                                                                                                window.innerWidth < 767 && ['bottom'].map((anchor) => (
                                                                                                                    <React.Fragment key={anchor}>
                                                                                                                        <Drawer
                                                                                                                            anchor={anchor}
                                                                                                                            open={showExplanation}
                                                                                                                            className='mobile-drawer'
                                                                                                                        >

                                                                                                                            {
                                                                                                                                showExplanation ?
                                                                                                                                    <div style={{ padding: 16 }} >
                                                                                                                                        <h1>{t('Explanation')}</h1>
                                                                                                                                        <div className='close-explaination' onClick={() => setShowExplanation(false)}><img src='/images/close1.svg' alt='' width='12px' /></div>
                                                                                                                                        <small dangerouslySetInnerHTML={{ __html: question?.explanation }} ></small>
                                                                                                                                    </div>
                                                                                                                                    : null
                                                                                                                            }

                                                                                                                        </Drawer>
                                                                                                                    </React.Fragment>))


                                                                                                            }

                                                                                                        </div>
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                    <div>
                                                                                        <div className='fixed-prev mt-4' style={{ marginLeft: window.innerWidth > 767 ? 12 : 5, cursor: 'pointer' }}>
                                                                                            {
                                                                                                count === 0 ?
                                                                                                    null :
                                                                                                    <div className='prev-quiz' onClick={() => {
                                                                                                        setCount(count - 1);
                                                                                                        setShowExplanation(false);
                                                                                                        dispatch(getQuestion(questions[count - 1]));
                                                                                                    }} ><img src='/images/left-arrow.png' width={12} alt='' />&nbsp;{t('Prev')}</div>
                                                                                            }
                                                                                            &nbsp;
                                                                                            {
                                                                                                count + 1 === questions?.length ? null :
                                                                                                    <div className='next-quiz' onClick={() => {
                                                                                                        setCount(count + 1);
                                                                                                        setShowExplanation(false);
                                                                                                        dispatch(getQuestion(questions[count + 1]));
                                                                                                    }} >{t('Next')}&nbsp;<img src='/images/right-arrow.png' width={12} alt='' /></div>
                                                                                            }
                                                                                            {count + 1 === questions?.length ?
                                                                                                <div className='next-quiz' onClick={quizsubmit} style={{ display: 'flex', marginLeft: 40, fontWeight: '600' }}>{t('Submit')}</div>
                                                                                                : null
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                quizCompleted ?
                                                                                    <Fragment>
                                                                                        <div className='col-md-6 offset-md-3 d-flex justify-content-center flex-column align-items-center quiz-complete'>
                                                                                            <div className='mt-3'>
                                                                                                <img src='/images/happy.gif' alt='' width='300px' height='300px' />
                                                                                            </div>
                                                                                            <p>{t('Yay! Quiz Completed')}</p>
                                                                                            <small>{t('Congrats, You have completed your quiz and your score is')}</small>
                                                                                            <div className='score'>
                                                                                                {score}/{questions?.length}
                                                                                            </div>
                                                                                            {/* <button
                                                                                onClick={() => reTakeQuiz(questions[0], 0, individualResource?._id)}
                                                                                className='start-btn'>
                                                                                Retake Quiz
                                                                            </button> */}

                                                                                        </div>

                                                                                    </Fragment>

                                                                                    :
                                                                                    <div className='col-md-6 offset-md-3 d-flex justify-content-center flex-column align-items-center'>
                                                                                        <h1>{individualResource?.title}</h1>
                                                                                        <p>{individualResource?.questions?.length} {t('Multiple Choice Question')}</p>
                                                                                        <div className='mt-3'>

                                                                                            <img src='/images/quiz-image.png' alt='' width={window.innerWidth > 767 ? '' : 340} />
                                                                                        </div>
                                                                                        <small className='desc text-center'>
                                                                                            {individualResource?.description}
                                                                                        </small>
                                                                                        <br />
                                                                                        <button onClick={() => goToQuestion(questions[qIndex === -1 ? 0 : qIndex], qIndex)} className='start-btn'>
                                                                                            {qIndex === -1 || qIndex === 0 ? 'Start Quiz' : 'Resume Quiz'}
                                                                                        </button>

                                                                                    </div>

                                                                        }

                                                                    </div>
                                                                </Fragment> :
                                                                (individualResource?.type === 'Document' || individualResource?.type === 'Article') ?
                                                                    <div className='content-middle'>
                                                                        {individualResource?.type === 'Document' ?
                                                                            <PdfReader url={individualResource?.url} />
                                                                            :
                                                                            <div className='article-view'>
                                                                                <p>{individualResource?.description}</p>
                                                                                <div className='text-center mt-3' >
                                                                                    <button onClick={() => showArticles(individualResource)}>{individualResource?.source === 'Baamboozle' ? 'Play Game' : 'View Article'}</button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    individualResource?.type === 'Video' ?
                                                                        <ReactPlayer url={individualResource?.url}
                                                                            config={{
                                                                                youtube: {
                                                                                    playerVars: { start: start, end: end, disablekb: 1, rel: 0 },
                                                                                    embedOptions: {
                                                                                        host: 'https://www.youtube-nocookie.com'
                                                                                    }
                                                                                }
                                                                            }}

                                                                            controls
                                                                            playing={true} autoPlay width='100%'
                                                                            height={window.innerWidth > 767 ? 430 : 233}
                                                                            onPlay={() => {
                                                                                onPlayChange();
                                                                                startTimer();
                                                                            }}
                                                                            onProgress={handleProgress}
                                                                            onBuffer={handleBuffer}
                                                                            onBufferEnd={handleBufferEnd}
                                                                            onPause={() => onPause(true)}
                                                                            onEnded={onEnded}
                                                                        />
                                                                        : null)

                                                    }
                                                </div>
                                                {individualResource?.type === 'Quiz' ? null :
                                                    <Fragment>
                                                        <div className='resource-like'>
                                                            <div className='like-btn' onClick={() => onLikeClick(individualResource)}>
                                                                <img src='/images/like.svg' alt='' /> &nbsp;&nbsp;{
                                                                    individualResource?.like ? t('Liked') : t('Like')}
                                                            </div>
                                                            <div className='like-btn' onClick={() => dispatch(openShare(individualResource))} >
                                                                <img src='/images/share.svg' alt='' /> &nbsp;&nbsp;{t('Share')}
                                                            </div>
                                                        </div>
                                                        <div className='resource-notes'>

                                                            <label className='d-flex align-items-center justify-content-between'>
                                                                <p className='d-flex align-items-center'>
                                                                    {t('Notes')}
                                                                    {(note && !isEdit) ? <p onClick={() => editNote()} className="float-left p-1 cursor-pointer"> <img src='/images/edit.svg' alt='' /></p> :
                                                                        (note && !isEditNo) ? <p onClick={() => editNote()} className="float-left p-1 cursor-pointer"> <img src='/images/edit.svg' alt='' /></p>
                                                                            : null
                                                                    }

                                                                </p>
                                                                {(note === null && isEdit) ? <aside className='float-right'>{charsLeft}</aside> :
                                                                    (note === null && isEditNo) ? <aside className='float-right'>{charsLeft}</aside> : null
                                                                }
                                                            </label>
                                                            {
                                                                note !== null ?
                                                                    <Fragment>
                                                                        {
                                                                            isEdit ?
                                                                                <textarea
                                                                                    rows={4} cols={4} placeholder={t('Type Something...')}

                                                                                    onChange={(e) => changeNotes(e.target.value)}
                                                                                    maxLength={maxChar}
                                                                                    value={noteValue}
                                                                                    className={isEdit ? 'form-control mt-2' : 'form-control input-edit'}
                                                                                    id='note'

                                                                                />
                                                                                :
                                                                                <div className='mt-2'>
                                                                                    {noteValue}
                                                                                </div>
                                                                        }
                                                                        {/* <span style={{ color: 'red' }}>{saveError}</span> */}
                                                                        <div className={isEdit ? 'removeWidth' : 'addWidth'}>

                                                                            {
                                                                                isEdit &&

                                                                                <p className='mt-2'>
                                                                                    <button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#5855d6' }} onClick={() => {
                                                                                        saveNote(individualResource?._id, individualResource?.type, noteValue)
                                                                                    }}>Save</button>&nbsp;
                                                                                    <button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#5855d6' }} onClick={() => cancel()}>Cancel</button>
                                                                                </p>

                                                                            }

                                                                        </div>
                                                                    </Fragment>
                                                                    : <Fragment>
                                                                        {
                                                                            isEditNo ?
                                                                                <textarea
                                                                                    rows={4} cols={4} placeholder={t('Type Something...')}
                                                                                    className='form-control mt-2'
                                                                                    onChange={(e) => changeNotes(e.target.value)}
                                                                                    maxLength={maxChar}
                                                                                    value={noteValue}
                                                                                    id='note'
                                                                                // disabled={isEditNo ? false : true}
                                                                                /> :
                                                                                <div className='mt-2'>
                                                                                    {noteValue}
                                                                                </div>
                                                                        }

                                                                        <span style={{ color: 'red' }}>{saveError}</span>
                                                                        <div className={isEditNo ? 'removeWidth' : 'addWidth'}>
                                                                            {isEditNo &&
                                                                                <button className='mt-2' style={{ background: 'white', border: 'none', color: '#5855d6', fontWeight: 700 }} onClick={() => {
                                                                                    saveNote(individualResource?._id, individualResource?.type, noteValue)
                                                                                }}>Save</button>
                                                                            }
                                                                        </div>

                                                                    </Fragment>
                                                            }
                                                        </div>
                                                    </Fragment>
                                                }

                                                {(window.innerWidth > 767) ? null :
                                                    ((individualResource?.type === 'Quiz' || individualResource?.type === 'Article' || individualResource?.type === 'Document' || individualResource?.type === 'Video' || quizCompleted) &&
                                                        <div className='prev-next-mobile'>
                                                            <h6 style={{ visibility: currentIndex === 0 ? 'hidden' : 'visible' }}
                                                                onClick={() => prevIndex(below)}
                                                            >
                                                                <img src='/images/chevron-left.svg' alt='' />&nbsp;&nbsp;
                                                                {t('Previous')}
                                                            </h6>
                                                            <h6
                                                                style={{ visibility: currentIndex === (resourceIndex?.length - 1) ? 'hidden' : 'visible' }}
                                                                onClick={() => prevIndex(above)}
                                                            > {t('Next')}&nbsp;&nbsp;
                                                                <img src='/images/chevron-right.svg' alt='' />
                                                            </h6>
                                                        </div>)
                                                }
                                            </div>
                                }
                            </div>
                        </div>
                    </div >

            }
            {
                window.innerWidth > 767 &&
                <Modal
                    show={articleView}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName={"elite-content-holder content-modal-width"}
                >

                    <div className="main-modal-header">
                        <h3 >{individualResource?.title}
                        </h3>
                        <p>{individualResource?.source}</p>
                    </div>

                    <div className='p-3 text-center'>
                        <p style={{ color: '#000000' }}>{t('This article is being viewed in a different pop up. Please close it to continue acessing other content.')}<br />
                            <img src={individualResource?.image} alt='' width='100%' height='160px' />
                            <br />{t(' To share/like the article click on the buttons below')}</p>

                        {individualResource &&
                            <ul className='like-btn justify-content-center' style={{ marginLeft: window.innerWidth > 767 ? '' : 27 }} >
                                <li className={individualResource?.liked ? "liked-active" : null}
                                    onClick={() => onLikeClick(individualResource)}
                                >

                                    <img src="/images/like.svg" alt='' />&nbsp;
                                    {
                                        individualResource?.like ? t('Liked') : t('Like')
                                    }
                                </li>

                                <li
                                    onClick={() => dispatch(openShare(individualResource))}
                                >
                                    <img src='/images/share.svg' alt='' /> {t('Share')}
                                </li>
                            </ul >}
                        <button className="button-color" onClick={() => goToArticles(individualResource?.url, false)}>{t('Close')} {t(`${(individualResource?.type)}`)}</button>
                    </div>
                </Modal>
            }

            <SharePopup />
        </div >
    )
}


